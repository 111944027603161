import { Component, Input } from '@angular/core';
import { NavigationButtonState } from '../models/navigationButtonState';

@Component({
  selector: 'app-navigation-step',
  templateUrl: './navigation-step.component.html',
  styleUrls: ['./navigation-step.component.css']
})
export class NavigationStepComponent {
  @Input() redirectLinkName: string = '';
  @Input() name: string = '';
  @Input() index: string = '';
  @Input() navState: NavigationButtonState = 'inactive';
  styleClass: string = '';

  constructor() {

    
  }

  ngOnInit(): void {
    switch (this.navState) {
      case 'active':
        this.styleClass = 'w-full p-4 text-blue-700 bg-blue-100 border border-blue-300 rounded-lg dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400';
        break;
      case 'inactive':
        this.styleClass = 'w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400';
        break;
      case 'complete':
        this.styleClass = 'w-full p-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:border-green-800 dark:text-green-400';
        break;
      default:
        this.styleClass = 'w-full p-4 text-gray-900 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400';
    }
  }

}
