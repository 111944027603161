<div header>
    <h2>License Agreement</h2>
    <div class="max-h-60 overflow-y-auto mt-4 mb-8 p-4 border rounded" style="width: 500px;">
        <p class="text-base">
            1. SAAS SERVICES AND SUPPORT
            1.1 Subject to the terms of this Agreement, Company will
            use commercially reasonable efforts to provide Customer the
            Services. As part of the registration process, Customer will
            identify an administrative user name and password for
            Customer’s Company account. Company reserves the right to
            refuse registration of, or cancel passwords it deems
            inappropriate.
            1.2 Subject to the terms hereof, Company will provide
            Customer with reasonable technical support services in
            accordance with Company’s standard practice.
            2. RESTRICTIONS AND RESPONSIBILITIES
            2.1 Customer will not, directly or indirectly: reverse
            engineer, decompile, disassemble or otherwise attempt to
            discover the source code, object code or underlying structure,
            ideas, know-how or algorithms relevant to the Services or any
            software, documentation or data related to the Services
            (“Software”); modify, translate, or create derivative works based
            on the Services or any Software (except to the extent expressly
            permitted by Company or authorized within the Services); use
            the Services or any Software for timesharing or service bureau
            purposes or otherwise for the benefit of a third; or remove any
            proprietary notices or labels. With respect to any Software that is
            distributed or provided to Customer for use on Customer
            premises or devices, Company hereby grants Customer a non-
            exclusive, non-transferable, non-sublicensable license to use such
            Software during the Term only in connection with the Services.
            2.2 Further, Customer may not remove or export from the
            United States or allow the export or re-export of the Services,
            Software or anything related thereto, or any direct product
            thereof in violation of any restrictions, laws or regulations of the
            United States Department of Commerce, the United States
            Department of Treasury Office of Foreign Assets Control, or any
            other United States or foreign agency or authority. As defined in
            FAR section 2.101, the Software and documentation are
            “commercial items” and according to DFAR section
            252.2277014(a)(1) and (5) are deemed to be “commercial
            computer software” and “commercial computer software
            documentation.” Consistent with DFAR section 227.7202 and
            FAR section 12.212, any use modification, reproduction, release,
            performance, display, or disclosure of such commercial software
            or commercial software documentation by the U.S. Government
            will be governed solely by the terms of this Agreement and will
            be prohibited except to the extent expressly permitted by the
            terms of this Agreement.
            2.3 Customer represents, covenants, and warrants that
            Customer will use the Services only in compliance with the
            Company’s standard published policies then in effect (the
            “Policy”) and all applicable laws and regulations. Customer
            hereby agrees to indemnify and hold harmless the Company
            against any damages, losses, liabilities, settlements, and expenses

            (including, without limitation, costs and attorneys’ fees) in
            connection with any claim or action that arises from an alleged
            violation of the foregoing or otherwise from Customer’s use of
            the Services. Although the Company has no obligation to
            monitor Customer’s use of the Services, the Company may do so
            and may, in its sole discretion, suspend or terminate Customer’s
            access to the Services or prohibit any use of the Services it
            believes may be (or is alleged to be) in violation of the foregoing.
            2.4 Customer shall be responsible for obtaining and
            maintaining any equipment and ancillary services needed to
            connect to, access or otherwise use the Services, including,
            without limitation, modems, printers, hardware, servers,
            software, operating systems, networking, web servers and the
            like (collectively, “Equipment”). Customer shall also be
            responsible for maintaining and updating prices in the Customer
            Portal and the Company will not be responsible for lost sales or
            price discrepancies. Customer shall also be responsible for
            maintaining the security of the Equipment, Customer account,
            passwords (including but not limited to administrative and user
            passwords) and files, and for all uses of Customer account or the
            Equipment with or without Customer’s knowledge or consent.
            2.5 Customer is responsible for determining and setting the
            retail price for each Item to be made available for sale via
            BlazeBite (the “Retail Price”). As the Merchant of Record,
            Customer is the “retailer” or “seller” of all Items and is solely
            responsible for the collection and remittance of all applicable
            Sales Taxes and other fees. The term “Sales Tax” includes any
            sales, sellers use, transaction privilege, privilege, general excise,
            gross receipts, Item taxes and similar transaction taxes. For the
            sake of clarity, the Retail Price for each Item excludes Sales Tax
            or any other fees. Customer is solely responsible for determining
            all applicable Sales Tax and other fees and identifying and
            informing Blazebite of the appropriate Sales Tax and other fee
            amount for BlazeBite to charge Users on Customer’s behalf for
            Items available on the BlazeBite app. Further, Customer
            expressly authorizes BlazeBite, at Customer’s direction, to
            collect such Sales Taxes and other fees on Customer’s behalf.
            2.6 Customer acknowledges and agrees that BlazeBite takes
            no title to any Item at any time. Notwithstanding, Customer shall
            be responsible for any reimbursement costs related to User
            refunds for Substandard Items or other related issues within
            Customer’s control (including any costs associated with
            retrieving any such Substandard Items or otherwise
            unsatisfactory Item(s), if applicable)), including by way of
            example, missing or incomplete Items, Items not cooked
            thoroughly, and Items not prepared in accordance with
            Customer’s internal standards. BlazeBite may, in its sole
            discretion, deduct reimbursement costs from the payment
            BlazeBite remits to Customer.
            2.7 Customer, along with its employees and volunteers, is
            solely responsible for verifying that all orders for alcohol or any
            other items requiring age restrictions are made by individuals of

            4
            legal age. The Customer agrees to ensure that all employees and
            volunteers are properly trained on using the BlazeBite system to
            comply with age-related laws and regulations. The Customer
            agrees not to hold the Company responsible for any violations of
            age-related laws or regulations.
            2.8 If BlazeBite supplies any equipment, including but not
            limited to a tablet, mobile device, POS terminal, or any other
            hardware (“Equipment”) to Customer for use in connection with
            the availability of Items via the BlazeBite app, Customer agrees
            that: (i) such Equipment may only be used for the purpose of
            accepting orders via the BlazeBite App, and (ii) the Equipment
            may not be transferred, loaned, sold, or otherwise provided in
            any manner to any third party. The Equipment will at all times
            remain the property of BlazeBite, and upon expiration or
            termination of this Agreement, or in the event of an extended
            absence of all of Customer’s location(s) from the BlazeBite App
            for longer than forty-five (45) days, Customer will return all
            applicable Equipment to BlazeBite within ten (10) days. If
            Customer receives a wireless data plan for any Equipment,
            BlazeBite may require a weekly reimbursement from the
            Customer for the costs associated with the wireless data plan of
            each applicable piece of Equipment. Customer agrees that the
            loss or theft of any Equipment, the failure to timely return such
            Equipment, or any damage to the Equipment outside of normal
            wear and tear may result in a fee (“Damage Fee”). Customer
            agrees that BlazeBite may deduct the reimbursement or Damage
            Fee from the Item Revenue prior to remittance of such Item
            Revenue to Customer.
            2.9 For the sale of Items, unless otherwise selected by
            Customer, Customer agrees to allow Users to provide gratuities
            through the BlazeBite app. BlazeBite shall remit to Customer the
            total value of any gratuities provided by Users, less any
            applicable processing fees. It is the sole responsibility of the
            Customer to comply with all applicable laws (including tax,
            gratuity, social security, and employment laws where applicable)
            regarding the distribution of any gratuities.
            2.10 Visiting the Sites or sending emails to BlazeBite
            constitutes electronic communications. You consent to receive
            electronic communications and you agree that all agreements,
            notices, disclosures and other communications that we provide to
            you electronically, via email and on the Sites, satisfy any legal
            requirement that such communications be in writing.
            2.11 Both BlazeBite and Customer agree to comply with the
            Payment Card Industry Data Security Standards (PCI DSS) to the
            extent that each party handles, processes, stores, or transmits
            credit card data in connection with the Services. BlazeBite will
            maintain compliance with PCI DSS and implement reasonable
            security measures to protect cardholder data within its systems.
            The Customer is responsible for ensuring PCI DSS compliance
            for any credit card data it handles outside of BlazeBite&#39;s system.
            3. CONFIDENTIALITY; PROPRIETARY RIGHTS
            3.1 Each party (the “Receiving Party”) understands that the
            other party (the “Disclosing Party”) has disclosed or may
            disclose business, technical, or financial information relating to

            the Disclosing Party’s business (hereinafter referred to as
            “Proprietary Information” of the Disclosing Party). Proprietary
            Information of the Company includes non-public information
            regarding features, functionality, and performance of the Service.
            Proprietary Information of the Customer includes non-public
            data provided by Customer to Company to enable the provision
            of the Services (“Customer Data”). The Receiving Party agrees:
            (i) to take reasonable precautions, such as implementing
            industry-standard security measures, to protect such Proprietary
            Information, and (ii) not to use (except in performance of the
            Services or as otherwise permitted herein) or divulge to any third
            person any such Proprietary Information. The Disclosing Party
            agrees that the foregoing shall not apply to any information after
            five (5) years following the disclosure thereof or any information
            that the Receiving Party can document (a) is or becomes
            generally available to the public, or (b) was in its possession or
            known by it prior to receipt from the Disclosing Party, or (c) was
            rightfully disclosed to it without restriction by a third party, or
            (d) was independently developed without the use of any
            Proprietary Information of the Disclosing Party, or (e) is required
            to be disclosed by law.
            3.2 Company shall own and retain all right, title, and
            interest in and to (a) the Services and Software, all
            improvements, enhancements, or modifications thereto, (b) any
            software, applications, inventions, or other technology developed
            in connection with Implementation Services or support, and (c)
            all intellectual property rights related to any of the foregoing.
            3.3 Notwithstanding anything to the contrary, Company
            shall have the right to collect and analyze data and other
            information relating to the provision, use, and performance of
            various aspects of the Services and related systems and
            technologies (including, without limitation, information
            concerning User Data and data derived therefrom), and Company
            will be free (during and after the term hereof) to (i) use such
            information and data to improve and enhance the Services and
            for other development, diagnostic, and corrective purposes in
            connection with the Services and other Company offerings, and
            (ii) disclose such data solely in aggregate or other de-identified
            form in connection with its business, in a manner that does not
            identify the Customer or any individual end-user. The Company
            will handle all collected data in compliance with applicable data
            protection laws. No rights or licenses are granted except as
            expressly set forth herein.
            4. PAYMENT OF FEES
            4.1 Customer agrees to pay the applicable fees as outlined
            in the &quot;Service Fees&quot; section on the first page of this contract. If
            the Customer selects the Subscription Model, they agree to pay
            the fees as specified under the chosen subscription option, either
            on an annual or monthly basis, in accordance with the terms
            stated in the &quot;Service Fees&quot; section.
            4.2 If the Customer selects the Percentage of Sales Model,
            the Customer agrees to pay the percentage of total sales made
            through orders placed via the BlazeBite app as outlined in the
            &quot;Service Fees&quot; section.

            5
            4.2.1 Annual Payment: If the Customer selects the annual
            subscription model, they agree to pay the full amount for the year
            upfront, based on the rate described in the &quot;Service Fees&quot; section.
            This payment is made once at the start of the service term and
            covers the entire year of service.
            4.2.2 Monthly Payment: If the Customer selects the monthly
            subscription model, they will be billed on a monthly basis via the
            credit card on file, based on the rate described in the &quot;Service
            Fees&quot; section. If a credit card charge is declined, BlazeBite
            reserves the right to suspend the Customer&#39;s access to the
            services until the outstanding balance is settled. It is the
            Customer’s responsibility to ensure that their payment method
            remains valid and up-to-date.
            4.3 Percentage of Sales Model: If the Customer selects the
            percentage-based sales model as outlined in the &quot;Service Fees&quot;
            section, BlazeBite will deduct the agreed-upon percentage from
            each sale before depositing funds into the Customer&#39;s account.
            The payment to the customer will reflect the total sales minus the
            percentage fee. This deduction is processed automatically with
            each transaction made through the BlazeBite app.
            4.4 Stripe Credit Card Processing Fees: Regardless of the
            payment model selected, Stripe Credit Card Processing Fees of
            2.9% + $0.30 per transaction will be automatically deducted
            from each transaction before any funds are deposited into the
            Customer&#39;s account. These fees are managed directly by Stripe,
            and the Customer acknowledges and agrees that they are non-
            negotiable.
            4.5 BlazeBite will remit to the Customer the total Retail
            Price collected for all Items sold via the BlazeBite app, including
            any Sales Tax and other fees collected on the Customer&#39;s behalf,
            less the applicable retained Fee (as described in the selected
            Services Fee option) and any refunds processed through the
            BlazeBite system that were given to Users. Any refund claims or
            requests made directly by Users will be passed on to the
            Customer, who will be responsible for determining how these
            should be handled. BlazeBite will not issue refunds on behalf of
            the Customer without their explicit authorization. The remaining
            amount, known as the “Item Revenue,” will be remitted to the
            Customer within fourteen (14) business days of the sale of the
            Item. BlazeBite typically processes payments on a weekly basis.
            4.6 Late Payments and Suspensions: In cases where the
            Customer’s credit card payment is declined, or if the account
            becomes overdue, BlazeBite reserves the right to suspend the
            Customer’s access to the Services until the outstanding balance is
            fully settled.
            4.7 Finance Charges and Collection: Unpaid amounts are
            subject to a finance charge of 1.5% per month on any outstanding
            balance, or the maximum permitted by law, whichever is lower,
            plus all expenses of collection. This may result in immediate
            termination of the Service if not resolved.
            4.8 Inquiries and Discrepancies: If the Customer believes
            that an error has been made in billing, they must contact
            BlazeBite within 60 days after the closing date on the first billing

            statement in which the error appeared to receive an adjustment or
            credit. All inquiries should be directed to BlazeBite’s customer
            support department.
            5. TERM AND TERMINATION
            5.1 Subject to earlier termination as provided below, this
            Agreement is for the Initial Service Term as specified in the
            Order Form, and shall be automatically renewed for additional
            periods of the same duration as the Initial Service Term
            (collectively, the “Term”), unless either party requests
            termination at least thirty (30) days prior to the end of the then-
            current term.
            5.2 In addition to any other remedies it may have, either
            party may also terminate this Agreement upon thirty (30) days’
            notice (or without notice in the case of nonpayment), if the other
            party materially breaches any of the terms or conditions of this
            Agreement. Customer will pay in full for the Services up to and
            including the last day on which the Services are provided. Upon
            any termination, Company will make all Customer Data
            available to Customer for electronic retrieval for a period of
            thirty (30) days, but thereafter Company may, but is not
            obligated to, delete stored Customer Data. All sections of this
            Agreement which by their nature should survive termination will
            survive termination, including, without limitation, accrued rights
            to payment, confidentiality obligations, warranty disclaimers,
            and limitations of liability.
            6. WARRANTY AND DISCLAIMER
            6.1 Company shall use reasonable efforts consistent with
            prevailing industry standards to maintain the services in a
            manner which minimizes errors and interruptions and shall
            perform the implementation services in a professional and
            workmanlike manner. Services may be temporarily unavailable
            for scheduled maintenance or for unscheduled emergency
            maintenance, either by Company or by third-party providers, or
            because of other causes beyond Company’s reasonable control,
            but Company shall use reasonable efforts to provide advance
            notice in writing or by e-mail of any scheduled service
            disruption.
            6.2 However, Company does not warrant that the services
            will be uninterrupted or error free; nor does it make any warranty
            as to the results that may be obtained from use of the services.
            Except as expressly set forth in this section, the services and
            implementation services are provided “as is” and Company
            disclaims all warranties, express or implied, including, but not
            limited to, implied warranties of merchantability and fitness for a
            particular purpose and non-infringement.
            7. LIMITATION OF LIABILITY
            7.1 Notwithstanding anything to the contrary, except for
            bodily injury of a person, Company and its suppliers (including
            but not limited to all equipment and technology suppliers),
            officers, affiliates, representatives, contractors and employees
            shall not be responsible or liable with respect to any subject
            matter of this Agreement or terms and conditions related thereto
            under any contract, negligence, strict liability or other theory:

            6
            (a) for error or interruption of use or for loss or inaccuracy or
            corruption of data or cost of procurement of substitute goods,
            services or technology or loss of business; (b) for any indirect,
            exemplary, incidental, special or consequential damages; (c) for
            any matter beyond Company’s reasonable control; or (d) for any
            amounts that, together with amounts associated with all other
            claims, exceed the fees paid by Customer to Company for the
            services under this Agreement in the 12 months prior to the act
            that gave rise to the liability, in each case, whether or not
            Company has been advised of the possibility of such damages.
            8. MISCELLANEOUS
            8.1 You agree to indemnify, defend and hold harmless
            BlazeBite, its officers, directors, employees, agents and third
            parties, for any losses, costs, liabilities and expenses (including
            reasonable attorney’s fees) relating to or arising out of your use
            of or inability to use the app or services, any user postings made
            by you, your violation of any terms of this Agreement or your
            violation of any rights of a third party, or your violation of any
            applicable laws, rules or regulations. BlazeBite reserves the right,
            at its own cost, to assume the exclusive defense and control of
            any matter otherwise subject to indemnification by you, in which
            event you will fully cooperate with BlazeBite in asserting any
            available defenses.
            8.2 If any provision of this Agreement is found to be
            unenforceable or invalid, that provision will be limited or
            eliminated to the minimum extent necessary so that this
            Agreement will otherwise remain in full force and effect and
            enforceable. This Agreement is not assignable, transferable or
            sublicensable by Customer except with Company’s prior written
            consent. Company may transfer and assign any of its rights and
            obligations under this Agreement without consent. This
            Agreement is the complete and exclusive statement of the mutual
            understanding of the parties and supersedes and cancels all
            previous written and oral agreements, communications and other
            understandings relating to the subject matter of this Agreement,
            and that all waivers and modifications must be in a writing
            signed by both parties, except as otherwise provided herein. No
            agency, partnership, joint venture, or employment is created as a
            result of this Agreement and Customer does not have any
            authority of any kind to bind Company in any respect
            whatsoever. In any action or proceeding to enforce rights under
            this Agreement, the prevailing party will be entitled to recover
            costs and attorneys’ fees. All notices under this Agreement will
            be in writing and will be deemed to have been duly given when
            received, if personally delivered; when receipt is electronically
            confirmed, if transmitted by facsimile or e-mail; the day after it is
            sent, if sent for next day delivery by recognized overnight
            delivery service; and upon receipt, if sent by certified or
            registered mail, return receipt requested. This Agreement shall
            be governed by the laws of the State of Ohio without regard to its
            conflict of laws provisions. The parties shall work together in
            good faith to issue at least one mutually agreed upon press
            release within 90 days of the Effective Date, and Customer
            otherwise agrees to reasonably cooperate with Company to serve
            as a reference account upon request.

            8.3 In the event the parties are not able to resolve any
            dispute between them arising out of or concerning these Terms
            and Conditions, or any provisions hereof, whether in contract,
            tort, or otherwise at law or in equity for damages or any other
            relief, then such dispute shall be resolved only by final and
            binding arbitration pursuant to the Federal Arbitration Act,
            conducted by a single neutral arbitrator and administered by the
            American Arbitration Association, or a similar arbitration service
            selected by the parties, in a location mutually agreed upon by the
            parties. The arbitrator’s award shall be final, and judgement may
            be entered upon it in any court having jurisdiction. In the event
            that any legal or equitable action, proceeding or arbitration arises
            out of or concerns these Term and Conditions, the prevailing
            party shall be entitled to recover is costs and reasonable
            attorney’s fees. The parties agree to arbitrate all disputes and
            claims in regards to these Terms and Conditions or any disputes
            arising as a result of these Terms and Conditions, whether
            directly or indirectly, including Tort claims that are a result of
            these Terms and Conditions. The parties agree that the Federal
            Arbitration Act governs the interpretation and enforcement of
            this provision. The entire dispute, including the scope and
            enforceability of this arbitration provision shall be determined by
            the Arbitrator. This arbitration provision shall survive the
            termination of these Terms and Conditions.
            8.4 Any arbitration under these Terms and Conditions will
            take place on an individual basis; class arbitrations and
            class/representative/collective actions are not permitted. The
            parties agree that a party may bring claims against the other only
            in each’s individual capacity, and not as a plaintiff or class
            member in any putative class, collective and/or representative
            proceeding, such as in the form of a private attorney general
            action against the other. Further, unless both you and BlazeBite
            agree otherwise, the arbitrator may not consolidate more than one
            person’s claims and may not otherwise preside over any form of
            a representative or class proceeding.
            8.5 The information, software, products, and services
            included in or available through the site may include inaccuracies
            or typographical errors. Changes are periodically added to the
            information herein. BlazeBite and/or its suppliers may make
            improvements and/or changes in the app at any time.
            8.6 BlazeBite and/or its suppliers make no representations
            about the suitability, reliability, availability, timeliness, and
            accuracy of the information, software, products, services and
            related graphics contained on the app for any purpose. To the
            maximum extent permitted by the applicable law, all such
            information, software, products, services and related graphics are
            provided “as is” without warranty or condition of any kind.
            BlazeBite and/or its suppliers hereby disclaim all warranties and
            conditions with regard to this information, software, products,
            services and related graphics, including all implied warranties or
            conditions of merchantability, fitness for a particular purpose,
            title and non-infringement.
            8.7 To the maximum extent permitted by applicable law, in
            no event shall BlazeBite and/or its suppliers be liable for any
            direct, indirect, punitive, incidental, special, consequential

            7
            damages or any damages whatsoever including, without
            limitation, damages for loss of use, data or profits, arising out of
            or in any way connected with the use or performance of the app,
            with the delay or inability to use the app or related services, the
            provision of or failure to provide services, or for any information,
            software, products, services and related graphics obtained
            through the app, or otherwise arising out of the use of the app,
            whether based on contract, tort, negligence, strict liability or
            otherwise, even if BlazeBite or any of its suppliers has been
            advised of the possibility of damages. Because some
            states/jurisdictions do not allow the exclusion or limitations of
            liability for consequential or incidental damages, the above
            limitation may not apply to you. If you are dissatisfied with any
            portion of the app, or with any of these terms of use, your sole
            and exclusive remedy is to discontinue using the app.
        </p>
    </div>
</div>
<div>
    <div>
        <label class="inline-flex items-center">
            <input type="checkbox" class="form-checkbox" (change)="onChange($event)">
            <span class="ml-2">I agree</span>
        </label>
        <div>
            <div>
                <div class="mt-auto">
                    <a href="#" (click)="continue()" aria-describedby="tier-basic"
                        class="btn-primary">Continue</a>
                </div>
            </div>
        </div>
    </div>
</div>