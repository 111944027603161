<div class="flex justify-center items-start min-h-screen bg-white px-4 sm:px-0">
  <div class="max-w-2xl w-full">
    <h1 class="heading-primary">What type of venue do you operate?</h1>
    <p class="text-gray-600 mb-6">
      Select the venue type that best represents your business.
    </p>
    <div class="space-y-4 mb-6">
      <div *ngFor="let venue of venues">
        <button
          (click)="toggleDropdown(venue)"
          class="w-full text-left px-4 py-3 border border-gray-300 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 outline-none"
          [ngClass]="{'border-blue-500': expandedVenue === venue}"
        >
          {{ venue }}
        </button>
        <div *ngIf="expandedVenue === venue" class="mt-4 ml-4">
          <p class="text-gray-600 mb-4">
            Select the most relevant option for your {{ venue }}.
          </p>
          <div class="space-y-4">
            <button
              *ngFor="let option of optionsMap[venue]"
              (click)="onSubTypeSelect(option)"
              class="w-full text-left px-4 py-3 border border-gray-300 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 outline-none"
              [ngClass]="{
                'border-blue-500': 
                  (selectedVenue === venue && selectedSubType === option)
              }"
            >
              {{ option }}
            </button>
            <div class="mt-4">
              <button
                (click)="onSubTypeSelect('Other')"
                class="w-full text-left px-4 py-3 border border-gray-300 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 outline-none"
                [ngClass]="{
                  'border-blue-500':
                    (selectedVenue === venue && selectedSubType === 'Other')
                }"
              >
                Other
              </button>
              <div 
                *ngIf="(selectedVenue === venue && selectedSubType === 'Other')"
                class="mt-4"
              >
                <label class="block text-gray-700 mb-2">Specify your option:</label>
                <input
                  type="text"
                  [(ngModel)]="customOptions[venue]"
                  placeholder="Enter your custom option"
                  class="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      (click)="onContinue()"
      [disabled]="!isReadyToContinue()"
      class="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
    >
      Continue
    </button>
  </div>
</div>
