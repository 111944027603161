<div class="flex flex-col">
    <div>
      <label for="password" class="input-label-primary">Password</label>
      <div class="mt-2 grid grid-cols-1 relative">
        <input type="password" name="password" id="password" class="input-primary" [(ngModel)]="password" aria-invalid="true" aria-describedby="email-error">
        <input-error-icon [errors]="emailErrors" ></input-error-icon>
      </div>
      <input-error-list [errors]="emailErrors"></input-error-list>
    </div>
    <div>
        <label for="confirm" class="input-label-primary">Confirm Password</label>
        <div class="mt-2 grid grid-cols-1 relative">
          <input type="password" name="confirm" id="confirm" class="input-primary" [(ngModel)]="confirmPassword" aria-invalid="true" aria-describedby="email-error">
          <input-error-icon [errors]="emailErrors" ></input-error-icon>
        </div>
        <input-error-list [errors]="emailErrors"></input-error-list>
      </div>
    <div class="mt-auto">
        <a href="#" (click)="continue()"
    class="mt-10 btn-primary">Continue</a>
    </div>
<div>
