import { Component } from '@angular/core';

@Component({
  selector: 'app-confirmation-venue-created',
  templateUrl: './confirmation-venue-created.component.html',
  styleUrls: ['./confirmation-venue-created.component.css']
})
export class ConfirmationVenueCreatedComponent {

}
