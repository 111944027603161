import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { AdminFunctionsService } from '../admin-functions.service';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs';
import { LocalStorageKey } from '../models/storageKey';
import { ErrorMessage } from '../common/input-error-list/errorMessage';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent {
  email: string = '';
  confirmedEmail: string = '';
  doesEmailExist?: boolean;
  doEmailsMatch?: boolean;
  isProduction: boolean = false;
  isVerifying: boolean = false;
  test: boolean = true;
  emailErrors: ErrorMessage[] = [];

  doesEmailMatch: ErrorMessage = { test: false, message: 'Emails do not match' };
  emailExists: ErrorMessage = { test: false, message: 'Email already exists in our system' };
  emailValid: ErrorMessage = { test: false, message: 'Email is not valid' };

  constructor(
    private sessionStorage: SessionStorageService,
    private service: AdminFunctionsService,
  ) {

    const emailAddressExists = 'evan@blazebite.com';
    const emailAddressDoesNotExists = 'jeremy@schmitts.com';

    this.isProduction = environment.production;
    const exampleResponse = { isRegistered: true };
  }

  ngOnInit(): void {
    //used in html template for password verification field errorStateMatcher
  
  }

  createSignup() {
    this.isVerifying = true;
    console.log(`Email: ${this.email}`);
    if (this.email.length > 0) {
      this.service.checkForUser(this.email)
        .pipe(
          tap(result => console.log(`User is ${result.isRegistered}`)),
          map(result => result.isRegistered),
        )
        .pipe(tap(result => console.log(`Yo response here: ${JSON.stringify(result)}`)))
        .subscribe(
          result => {
            this.isVerifying = false;
            this.sessionStorage.newUserEmail.next(this.email);
            const data = this.sessionStorage.newProspectData();
            console.log(`Data: ${JSON.stringify(data)}`);
            if (result) {
              this.openSnackBar();
            } else {
              // this.stepper.next();  
            }
            
          },
          error => {
            this.isVerifying = false;
            console.log(`Error: ${JSON.stringify(error)}`);
            this.openSnackBar();
          }
        );
    }
  }


  checkEmailErrors() {
    if (this.email.length == 0 || this.confirmedEmail.length == 0) {
      return;
    }
    
    if (this.email === this.confirmedEmail) {
      console.log(`Email match: ${this.email}`);
      this.doEmailsMatch = true;
    } else {
      console.log(`Email don't match: ${this.email}`);
      this.doEmailsMatch = false;
    }
  }

  verifyUser() {
    console.log(`Email: ${this.email}`);
    this.isVerifying = true;
    if (this.email.length > 0) {
      this.service.checkForUser(this.email)
        .pipe(
          tap(result => console.log(`User is ${result.isRegistered}`)),
          map(result => result.isRegistered),
        )
        .pipe(tap(result => console.log(`Yo response here: ${JSON.stringify(result)}`)))
        .subscribe(
          result => {
            this.isVerifying = false;
            this.doesEmailExist = result as boolean;
            this.sessionStorage.newUserEmail.next(this.email);
            const data = this.sessionStorage.newProspectData();
            console.log(`Data: ${JSON.stringify(data)}`);
            if (result) {
              this.openSnackBar();
            } else {
              // this.stepper.next();  
            }
            
          },
          error => {
            this.isVerifying = false;
            this.doesEmailExist = false;
            console.log(`Error: ${JSON.stringify(error)}`);
            this.openSnackBar();
          }
        );
    }
  }

  continue() {
    this.sessionStorage.setItem(LocalStorageKey.USER_VERIFIED, 'complete');
  }

  openSnackBar() {
    // this.snackBar.open("Email already exists in our system.", "", {
    //   duration: 2000,
    // });
  }
}
