import { Component, Input } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { PlanDetails } from '../models/planDetails';
import { LocalStorageKey } from '../models/storageKey';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.css']
})
export class PlanSelectionComponent {
  @Input() plan: PlanDetails | null = null;
  @Input() selectingPlanName?: string | null = null;
  @Input() isAnnual: boolean = false;

  constructor(private sessionStorage: SessionStorageService, private router: Router) { }

  setPlan(plan: string) {
    this.sessionStorage.setItem(LocalStorageKey.SELECTED_PLAN, plan);
    this.sessionStorage.setItem(LocalStorageKey.BILLING_FREQUENCY, this.isAnnual ? 'annual' : 'monthly');
    this.sessionStorage.setItem(LocalStorageKey.PLAN, 'complete');
    this.router.navigate(['review']);
  }

  setPricing(isAnnual: boolean) {
    this.isAnnual = isAnnual;
  }
}
