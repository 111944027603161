import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage.service';
import { LocalStorageKey } from '../models/storageKey';

@Component({
  selector: 'app-venue-type',
  templateUrl: './venue-type.component.html',
  styleUrls: ['./venue-type.component.css']
})
export class VenueTypeComponent {
  venues: string[] = [
    'Concession Stand',
    'Food Truck',
    'Golf Course',
    'Stadium/Arena',
    'Sports Complex',
    'Cafeteria',
    'Movie Theater',
    'Other'
  ];

  optionsMap: Record<string, string[]> = {
    'Concession Stand': [
      'Elementary/Middle School',
      'High School',
      'College',
      'Minor League',
      'Professional'
    ],
    'Food Truck': [
      'Taco',
      'BBQ',
      'Burger',
      'Pizza',
      'Seafood',
      'Dessert',
      'Beverage'
    ],
    'Golf Course': [
      'Clubhouse Dining',
      'Snack Bar/Refreshment Stand',
      'Beverage Cart',
      'Event Catering',
      'Bar & Lounge'
    ],
    'Stadium/Arena': [
      'High School',
      'College',
      'Minor League',
      'Professional'
    ],
    'Sports Complex': [
      'Multi-Sport Complex',
      'Soccer Complex',
      'Baseball/Softball Complex',
      'Basketball Facility',
      'Football Complex',
      'Hockey Arena',
      'Tennis and Pickleball Center',
      'Track and Field Complex',
      'Aquatics Center'
    ],
    'Cafeteria': [
      'School',
      'College Dining Hall',
      'Corporate',
      'Hospital',
      'Factory/Industrial',
      'Mall/Food Court'
    ],
    'Movie Theater': [
      'Concession Stand',
      'Pickup',
      'In-Seat Delivery',
      'Dine-In',
      'Self-Serve',
      'Alcohol & Bar'
    ],
    'Other': [
      'Bar & Grill',
      'Bowling Alley',
      'Brewery',
      'Cafe or Bistro',
      'Fast Food',
      'Fine Dining',
      'Movie Theatre',
      'Restaurant',
      'Sports Bar',
      'Sports Complex',
      'Yacht Club'
    ]
  };

  selectedVenue: string | null = null;
  selectedSubType: string | null = null;
  expandedVenue: string | null = null;
  customOptions: Record<string, string> = {};

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  toggleDropdown(venue: string): void {
    if (this.expandedVenue === venue) {
      this.expandedVenue = null;
    } else {
      this.expandedVenue = venue;
      this.selectedVenue = venue;
      this.selectedSubType = null;
    }
  }

  onSubTypeSelect(subType: string): void {
    this.selectedSubType = subType;
  }

  isReadyToContinue(): boolean {
    if (!this.selectedVenue) return false;

    if (this.selectedSubType === 'Other') {
      const customValue = this.customOptions[this.selectedVenue] || '';
      return customValue.trim().length > 0;
    }

    return !!this.selectedSubType;
  }

  onContinue(): void {
    if (!this.isReadyToContinue()) {
      alert('Please select a venue and a sub-option before proceeding.');
      return;
    }

    if (this.selectedVenue) {
      this.sessionStorageService.setItem(
        LocalStorageKey.VENUE_TYPE,
        this.selectedVenue
      );
    }

    if (this.selectedSubType === 'Other') {
      const customValue = this.customOptions[this.selectedVenue!];
      this.sessionStorageService.setItem(
        LocalStorageKey.VENUE_TYPE_CONTINUED,
        customValue
      );
    } else if (this.selectedSubType) {
      this.sessionStorageService.setItem(
        LocalStorageKey.VENUE_TYPE_CONTINUED,
        this.selectedSubType
      );
    }

    this.sessionStorageService.setItem(LocalStorageKey.VENUE, 'complete');
    this.router.navigate(['/choosePlan']);
  }
}
