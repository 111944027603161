<bcs-header></bcs-header>
<div class="p-8">
  <div class="flex flex-col space-y-8">
    <div class="flex space-x-4 justify-center">
      <app-navigation-step index="1" redirectLinkName="login" name="Login" [navState]="loginComplete"></app-navigation-step>
      <app-navigation-step index="2" redirectLinkName="venueType" name="Venue Type" [navState]="venueTypeComplete"></app-navigation-step>
      <app-navigation-step index="3" redirectLinkName="choosePlan" name="Select Plan" [navState]="planComplete"></app-navigation-step>
      <app-navigation-step index="4" redirectLinkName="review" name="Review Venue Info" [navState]="reviewVenueComplete"></app-navigation-step>
      <app-navigation-step index="5" redirectLinkName="confirmation" name="Confirmation" [navState]="confirmationActive"></app-navigation-step>
    </div>
    <div class="p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>