import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { LocalStorageKey } from '../models/storageKey';

@Component({
  selector: 'app-check-agreement',
  templateUrl: './check-agreement.component.html',
  styleUrls: ['./check-agreement.component.css']
})
export class CheckAgreementComponent {
  checked: boolean = false;
  constructor(private sessionStorage: SessionStorageService) {
  }
  onChange(event: any) {
    console.log('onChange');
  }

  continue() {
    this.sessionStorage.setItem(LocalStorageKey.USER_AGREEMENT, 'complete');
  }
}
