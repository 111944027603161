import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';

@Component({
  selector: 'app-venue-confirmation',
  templateUrl: './venue-confirmation.component.html',
  styleUrls: ['./venue-confirmation.component.css']
})
export class VenueConfirmationComponent {
  constructor(private sessionStorage: SessionStorageService) { }
  reset() {
    this.sessionStorage.clearAllStorage();
  }
}
