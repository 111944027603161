import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage.service';
import { AdminFunctionsService } from '../admin-functions.service';
import { map, mergeMap, tap, of } from 'rxjs';
import { LocalStorageKey } from '../models/storageKey';

@Component({
  selector: 'app-review-venue',
  templateUrl: './review-venue.component.html',
  styleUrls: ['./review-venue.component.css']
})
export class ReviewVenueComponent implements OnInit {
  email: string = '';
  businessName: string = '';
  venueType: string = '';
  venueTypeContinued: string = '';
  selectedPlan: string = '';
  billingFrequency: string = '';
  passwordValue: string = '';
  isLoading: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private adminFunctionsService: AdminFunctionsService
  ) {}

  ngOnInit(): void {
    const storage = this.sessionStorageService.getAllStorage();

    this.email = storage[LocalStorageKey.USER_EMAIL] || 'Unknown Email';
    this.businessName = storage[LocalStorageKey.BUSINESS_NAME] || 'Unknown Business Name';
    this.venueType = storage[LocalStorageKey.VENUE_TYPE] || 'Unknown Venue Type';
    this.venueTypeContinued = storage[LocalStorageKey.VENUE_TYPE_CONTINUED] || 'Unknown Venue Type Details';
    this.selectedPlan = storage[LocalStorageKey.SELECTED_PLAN] || 'No Plan Selected';
    this.billingFrequency = storage[LocalStorageKey.BILLING_FREQUENCY] || 'monthly';
    this.passwordValue = storage[LocalStorageKey.USER_PASSWORD] || '';
  }

  submitVenue(): void {
    if (!this.email || !this.businessName || !this.venueType || !this.selectedPlan) {
      alert('Please complete all required fields before submitting.');
      return;
    }

    const venueData = {
      venueName: this.businessName,
      venueType: this.venueType,
      venueTypeContinued: this.venueTypeContinued,
      selectedPlan: this.selectedPlan,
      billingFrequency: this.billingFrequency
    };

    console.log('Venue data:', JSON.stringify(venueData));
    this.isLoading = true;

    this.adminFunctionsService.createNewUnverifiedUser(this.email, this.passwordValue, venueData)
      .pipe(
        tap(response => {
          console.log('Create Venue Response:', response);
        }),
        mergeMap(() => {
          this.sessionStorageService.setItem(LocalStorageKey.REVIEW_VENUE, 'complete');
          return of(null);
        })
      )
      .subscribe(
        () => {
          this.isLoading = false;
          alert('Venue created successfully!');
          this.router.navigate(['/confirmation']);
        },
        error => {
          this.isLoading = false;
          console.error('Error creating venue:', error);
          alert('An error occurred while creating the venue. Please try again.');
        }
      );
  }

  navigateToEdit(section: string): void {
    switch (section) {
      case 'email':
        this.router.navigate(['/login']);
        break;
      case 'businessName':
        this.router.navigate(['/businessName']);
        break;
      case 'venueType':
        this.router.navigate(['/venueType']);
        break;
      case 'venueTypeContinued':
        this.router.navigate(['/venueTypeContinued']);
        break;
      case 'selectedPlan':
        this.router.navigate(['/choosePlan']);
        break;
      default:
        break;
    }
  }
}
