import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { LocalStorageKey } from '../models/storageKey';
import { ErrorMessage } from '../common/input-error-list/errorMessage';

@Component({
  selector: 'app-setup-credentials',
  templateUrl: './setup-credentials.component.html',
  styleUrls: ['./setup-credentials.component.css']
})
export class SetupCredentialsComponent {
  emailErrors: ErrorMessage[] = [];
  password: string = '';
  confirmPassword: string = '';
  constructor(private sessionStorage: SessionStorageService) { }
  continue() {
    this.sessionStorage.setItem(LocalStorageKey.SETUP_USER_CREDENTIALS, 'complete');
  }
}
