<div class="p-6 max-w-2xl mx-auto border border-gray-300 rounded-lg">
  <h2 class="text-lg font-semibold mb-6">Review Venue Info</h2>
  <div class="grid grid-cols-2 items-baseline mb-6 gap-x-5">
    <div>
      <h3 class="font-medium text-gray-700">Email</h3>
      <p class="text-gray-600">{{ email }}</p>
    </div>
    <a 
    (click)="navigateToEdit('email')" 
    class="text-blue-500 justify-self-end cursor-pointer"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      class="w-5 h-5"
    >
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M16.862 4.487c-.393-.39-.866-.731-1.414-.972L5.83 13.133c-.218.218-.402.504-.532.804l-.847 3.124 3.124-.847c.3-.13.586-.314.804-.532L19.515 5.9c-.24-.548-.582-1.021-.972-1.414z" 
      />
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M18.44 2.44a2.25 2.25 0 00-3.182 0l-.586.586c-.39.39-.731.866-.972 1.414l2.82 2.82c.548-.24 1.024-.581 1.414-.972l.586-.586a2.25 2.25 0 000-3.182z" 
      />
    </svg>
  </a>
  </div>
  <div class="grid grid-cols-2 items-baseline mb-6 gap-x-5">
    <div>
      <h3 class="font-medium text-gray-700">Business Name</h3>
      <p class="text-gray-600">{{ businessName }}</p>
    </div>
    <a 
    (click)="navigateToEdit('email')" 
    class="text-blue-500 justify-self-end cursor-pointer"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      class="w-5 h-5"
    >
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M16.862 4.487c-.393-.39-.866-.731-1.414-.972L5.83 13.133c-.218.218-.402.504-.532.804l-.847 3.124 3.124-.847c.3-.13.586-.314.804-.532L19.515 5.9c-.24-.548-.582-1.021-.972-1.414z" 
      />
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M18.44 2.44a2.25 2.25 0 00-3.182 0l-.586.586c-.39.39-.731.866-.972 1.414l2.82 2.82c.548-.24 1.024-.581 1.414-.972l.586-.586a2.25 2.25 0 000-3.182z" 
      />
    </svg>
  </a>  
  </div>
  <div class="grid grid-cols-2 items-baseline mb-6 gap-x-5">
    <div>
      <h3 class="font-medium text-gray-700">Venue Type</h3>
      <p class="text-gray-600">{{ venueType }}</p>
    </div>
    <a 
    (click)="navigateToEdit('venueType')" 
    class="text-blue-500 justify-self-end cursor-pointer"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      class="w-5 h-5"
    >
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M16.862 4.487c-.393-.39-.866-.731-1.414-.972L5.83 13.133c-.218.218-.402.504-.532.804l-.847 3.124 3.124-.847c.3-.13.586-.314.804-.532L19.515 5.9c-.24-.548-.582-1.021-.972-1.414z" 
      />
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M18.44 2.44a2.25 2.25 0 00-3.182 0l-.586.586c-.39.39-.731.866-.972 1.414l2.82 2.82c.548-.24 1.024-.581 1.414-.972l.586-.586a2.25 2.25 0 000-3.182z" 
      />
    </svg>
  </a>  
  </div>
  <div class="grid grid-cols-2 items-baseline mb-6 gap-x-5">
    <div>
      <h3 class="font-medium text-gray-700">Venue Type Continued</h3>
      <p class="text-gray-600">{{ venueTypeContinued }}</p>
    </div>
    <a 
    (click)="navigateToEdit('venueType')" 
    class="text-blue-500 justify-self-end cursor-pointer"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      class="w-5 h-5"
    >
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M16.862 4.487c-.393-.39-.866-.731-1.414-.972L5.83 13.133c-.218.218-.402.504-.532.804l-.847 3.124 3.124-.847c.3-.13.586-.314.804-.532L19.515 5.9c-.24-.548-.582-1.021-.972-1.414z" 
      />
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M18.44 2.44a2.25 2.25 0 00-3.182 0l-.586.586c-.39.39-.731.866-.972 1.414l2.82 2.82c.548-.24 1.024-.581 1.414-.972l.586-.586a2.25 2.25 0 000-3.182z" 
      />
    </svg>
  </a>  
  </div>
  <div class="grid grid-cols-2 items-baseline mb-6 gap-x-5">
    <div class="flex items-baseline space-x-2">
      <h3 class="font-medium text-gray-700">Plan Selected:</h3>
      <p class="text-gray-600">{{ selectedPlan | titlecase }}</p>
      <span class="text-gray-500">|</span>
      <p class="text-gray-600">{{ billingFrequency | titlecase }}</p>
    </div>
    <a 
    (click)="navigateToEdit('selectedPlan')" 
    class="text-blue-500 justify-self-end cursor-pointer"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="none" 
      viewBox="0 0 24 24" 
      stroke-width="1.5" 
      stroke="currentColor" 
      class="w-5 h-5"
    >
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M16.862 4.487c-.393-.39-.866-.731-1.414-.972L5.83 13.133c-.218.218-.402.504-.532.804l-.847 3.124 3.124-.847c.3-.13.586-.314.804-.532L19.515 5.9c-.24-.548-.582-1.021-.972-1.414z" 
      />
      <path 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        d="M18.44 2.44a2.25 2.25 0 00-3.182 0l-.586.586c-.39.39-.731.866-.972 1.414l2.82 2.82c.548-.24 1.024-.581 1.414-.972l.586-.586a2.25 2.25 0 000-3.182z" 
      />
    </svg>
  </a>  
  </div>
  <div class="mt-6">
    <button 
      (click)="submitVenue()" 
      class="w-full bg-purple-600 hover:bg-purple-700 text-white font-medium py-2 rounded-lg"
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading">Submit</span>
      <span *ngIf="isLoading">
        <svg
          class="animate-spin h-5 w-5 text-white inline-block"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Submitting...
      </span>
    </button>
  </div>
</div>
