<div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl sm:text-center">
        <p class="mt-2 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl">
          Select the plan that fits your venue
        </p>
      </div>
      <div class="flex justify-center mt-8 mb-10">
        <div class="relative inline-flex items-center rounded-full bg-gray-100 p-1">
          <button 
            class="py-2 px-4 text-sm font-semibold rounded-full"
            [class.bg-white]="isAnnual"
            [class.text-gray-900]="isAnnual"
            [class.text-gray-500]="!isAnnual"
            (click)="setPricing(true)">
            Pay yearly
          </button>
          <button 
            class="py-2 px-4 text-sm font-semibold rounded-full"
            [class.bg-white]="!isAnnual"
            [class.text-gray-900]="!isAnnual"
            [class.text-gray-500]="isAnnual"
            (click)="setPricing(false)">
            Pay monthly
          </button>
        </div>
      </div>
      <div class="mt-20 flow-root">
        <div class="isolate grid grid-cols-1 gap-y-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8">
          <div *ngFor="let p of plans">
            <app-plan-selection 
              [plan]="p" 
              [selectingPlanName]="selectedPlanName" 
              [isAnnual]="isAnnual">
            </app-plan-selection>
          </div>
        </div>
      </div>
    </div>
  </div>
  