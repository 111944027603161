import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChooseServicePlanComponent } from './choose-service-plan/choose-service-plan.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { CheckAgreementComponent } from './check-agreement/check-agreement.component';
import { CollectVenueDataComponent } from './collect-venue-data/collect-venue-data.component';
import { SetupCredentialsComponent } from './setup-credentials/setup-credentials.component';
import { ReviewVenueComponent } from './review-venue/review-venue.component';
import { VenueConfirmationComponent } from './venue-confirmation/venue-confirmation.component';
import { LoginComponent } from './login/login.component';
import { BusinessNameComponent } from './business-name/business-name.component';
import { VenueTypeComponent } from './venue-type/venue-type.component';
import { VenueTypeContinuedComponent } from './venue-type-continued/venue-type-continued.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  { path: 'businessName', component: BusinessNameComponent },
  { path: 'venueType', component: VenueTypeComponent },
  { path: 'venueTypeContinued', component: VenueTypeContinuedComponent },
  { path: 'choosePlan', component: ChooseServicePlanComponent },
  { path: 'checkUser', component: VerifyUserComponent },
  { path: 'collect', component: CollectVenueDataComponent },
  { path: 'license', component: CheckAgreementComponent },
  { path: 'credentials', component: SetupCredentialsComponent },
  { path: 'review', component: ReviewVenueComponent },
  { path: 'confirmation', component: VenueConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
