<div class="flex justify-center items-start min-h-screen bg-white">
  <div class="bg-white p-8 rounded-lg shadow-md max-w-2xl w-full">
    <h1 class="heading-primary">Let's create your account.</h1>
    <p class="text-gray-600 mb-4 text-center">
      Signing up for Blazebite is fast and easy. No commitments or long-term contracts required.
    </p>
    <form>
      <div>
        <label for="email" class="input-label-primary">Email address</label>
        <input
          type="email"
          id="email"
          placeholder="you@example.com"
          class="input-primary"
          [(ngModel)]="email"
          name="email"
          (ngModelChange)="onFieldChange()"
        />
      </div>
      <div>
        <label for="password" class="input-label-primary">Password</label>
        <input
          type="password"
          id="password"
          placeholder=""
          class="input-primary"
          [ngClass]="{'border-red-500': passwordsDoNotMatch}" 
          [(ngModel)]="password"
          name="password"
          (ngModelChange)="onFieldChange()"
        />
      </div>
      <div class="mb-4">
        <label for="confirmPassword" class="input-label-primary">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          class="input-primary"
          [ngClass]="{'border border-red-500 bg-red-100': passwordsDoNotMatch}"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          (ngModelChange)="onFieldChange()"
        />
        <p 
          class="text-red-500 text-sm mt-1"
          *ngIf="passwordsDoNotMatch"
        >
          Passwords do not match
        </p>
      </div>
      <h2 class="heading-secondary mt-6">Tell us about your business</h2>
      <p class="text-gray-600 mb-6">
        Together let’s tailor Blazebite for your business.
      </p>
      <div class="mb-4">
        <label for="business-name" class="sr-only">Business name</label>
        <input
          type="text"
          id="business-name"
          placeholder="Business name"
          class="input-primary"
          [(ngModel)]="businessName"
          name="businessName"
          (ngModelChange)="onFieldChange()"
        />
      </div>
      <p class="text-gray-500 text-sm mb-6">
        Your business name is how we’ll identify you on emails, receipts, and messages to customers.
      </p>
      <div>
        <label for="locale" class="input-label-primary">Locale</label>
        <select
          id="locale"
          class="input-primary"
          [(ngModel)]="locale"
          name="locale"
          (ngModelChange)="onFieldChange()"
        >
          <option value="en-US">United States (English)</option>
          <option value="en-CA">Canada (English)</option>
          <option value="fr-CA">Canada (French)</option>
        </select>
      </div>
      <div class="mb-3 flex items-center">
        <input
          type="checkbox"
          id="agree"
          class="mr-2"
          [(ngModel)]="agreeTerms"
          name="agreeTerms"
          (ngModelChange)="onFieldChange()"
        />
        <label for="agree" class="text-gray-700 text-sm">
          I agree to Blazebite's
          <a href="#" class="text-blue-500 underline">Terms</a>,
          <a href="#" class="text-blue-500 underline">Privacy Policy</a>, and
          <a href="#" class="text-blue-500 underline">E-Sign Consent</a>.
        </label>
      </div>
      <button
        type="button"
        class="btn-primary"
        (click)="submitForm()"
        [disabled]="
          !email ||
          !password ||
          !confirmPassword ||
          !businessName ||
          !agreeTerms
        "
      >
        Continue
      </button>
    </form>
  </div>
</div>
