import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { HeaderComponent } from './header/header.component';
import { NgxMaskModule } from 'ngx-mask';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { ChooseServicePlanComponent } from './choose-service-plan/choose-service-plan.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { CheckAgreementComponent } from './check-agreement/check-agreement.component';
import { CollectVenueDataComponent } from './collect-venue-data/collect-venue-data.component';
import { ReviewVenueComponent } from './review-venue/review-venue.component';
import { ConfirmationVenueCreatedComponent } from './confirmation-venue-created/confirmation-venue-created.component';
import { SetupCredentialsComponent } from './setup-credentials/setup-credentials.component';
import { VenueConfirmationComponent } from './venue-confirmation/venue-confirmation.component';
import { NavigationStepComponent } from './navigation-step/navigation-step.component';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { InputErrorListComponent } from './common/input-error-list/input-error-list.component';
import { InputErrorIconComponent } from './common/input-error-icon/input-error-icon.component';
import { LoginComponent } from './login/login.component';
import { BusinessNameComponent } from './business-name/business-name.component';
import { CommonModule } from '@angular/common';
import { VenueTypeComponent } from './venue-type/venue-type.component';
import { VenueTypeContinuedComponent } from './venue-type-continued/venue-type-continued.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    ChooseServicePlanComponent,
    VerifyUserComponent,
    CheckAgreementComponent,
    CollectVenueDataComponent,
    ReviewVenueComponent,
    ConfirmationVenueCreatedComponent,
    SetupCredentialsComponent,
    VenueConfirmationComponent,
    NavigationStepComponent,
    PlanSelectionComponent,
    InputErrorListComponent,
    InputErrorIconComponent,
    LoginComponent,
    BusinessNameComponent,
    VenueTypeComponent,
    VenueTypeContinuedComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => {
      const config = getRemoteConfig()
      config.fetchTimeMillis = 1000
      return config
    }),
    provideFirestore(() => getFirestore())
  ],
  providers: [
    { provide: 'REQUIRED', useValue: 'You must enter a value.' },
    ScreenTrackingService, UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
