<div class="flex justify-center items-start min-h-screen bg-white px-4 sm:px-0">
    <div class="max-w-2xl w-full">
      <h1 class="heading-primary">Tell us about your business</h1>
      <p class="text-gray-600 mb-6">
        Together let’s tailor Blazebite for your business.
      </p>
      <div class="mb-4">
        <label for="business-name" class="sr-only">Business name</label>
        <input
          type="text"
          id="business-name"
          placeholder="Business name"
          class="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
          [(ngModel)]="businessName"
        />
      </div>
      <p class="text-gray-500 text-sm mb-6">
        Your business name is how we’ll identify you on emails, receipts, and messages to customers.
      </p>
      <button
        type="button"
        (click)="onContinue()"
        class="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-200"
      >
        Continue
      </button>
    </div>
  </div>
  