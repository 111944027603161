import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { map, Observable } from 'rxjs';
import { PlanDetails } from '../models/planDetails';
import { LocalStorageKey } from '../models/storageKey';
@Component({
  selector: 'app-choose-service-plan',
  templateUrl: './choose-service-plan.component.html',
  styleUrls: ['./choose-service-plan.component.css']
})
export class ChooseServicePlanComponent {
  selectedPlanName: string = '';
  isAnnual: boolean = true;
  plans: PlanDetails[] = [
    {
      name: 'starter',
      displayName: 'Starter',
      tagline: 'Ideal for Smaller venues, occasional events, and pop-up activities',
      monthlyPrice: '0',
      annualMonthlyPrice: '0',
      annualPrice: '0',
      descriptionLine1: 'No monthly fees',
      descriptionLine2: 'UNLIMITED orders per/month',
      descriptionLine3: '10% convenience fee per transaction',
      features: ['Basic Mobile Ordering Setup', 
                 'Access to Reporting',
                 'Online Payments (credit card, Apple Pay, Google Pay)',
                 'Menu Customizations',
                 'Volunteer Training',
                 'Push notifications for orders',
                 'Customer Response Team']
    },
    {
      name: 'nonprofit',
      displayName: 'Non-Profit',
      tagline: 'Ideal for 501(c)(3) Non-Profit Schools (Booster Clubs, CYO)',
      monthlyPrice: '44',
      annualMonthlyPrice: '39',
      annualPrice: '468',
      descriptionLine1: 'billed monthly/annually',
      descriptionLine2: 'Up to 2,000 orders per/month',
      descriptionLine3: 'NO CONVENIENCE FEE',
      features: ['All Free Plan Features',
                 'Interactive Reporting Dashboard', 
                 'Order Ahead/Pre-Orders',
                 'Dedicated Support Line',
                 'Sponsorship Opportunities for Additional Revenue',
                 'Marketing Materials & Signage']
    },
    {
      name: 'essential',
      displayName: 'Essential',
      tagline: 'Ideal for concession-based business (golf courses, food trucks, concerts)',
      monthlyPrice: '69',
      annualMonthlyPrice: '59',
      annualPrice: '708',
      descriptionLine1: 'billed monthly/annually',
      descriptionLine2: 'Up to 2,000 orders per/month',
      descriptionLine3: 'NO CONVENIENCE FEE',
      features: ['All Free Plan Features',
                 'Interactive Reporting Dashboard', 
                 'Order Ahead/Pre-Orders',
                 'Dedicated Support Line',
                 'Sponsorship Opportunities for Additional Revenue',
                 'Marketing Materials & Signage']
    },
    {
      name: 'enterprise',
      displayName: 'Enterprise',
      tagline: 'Ideal for LARGE concession stands (stadiums, arenas, colleges)',
      monthlyPrice: '144',
      annualMonthlyPrice: '129',
      annualPrice: '1548',
      descriptionLine1: 'billed monthly/annually',
      descriptionLine2: 'Up to 10,000 orders per/month',
      descriptionLine3: 'NO CONVENIENCE FEE',
      features: ['All Essential Plan Features',
                 'API Access for Integration', 
                 'Personal Support Rep',
                 'Remote Log-in to Tablet',
                 'Priority Updates and Features',
                 'Staff App',
                 'Custom Marketing Campaigns',
                 'Discounts on Multiple Units']
    }
  ];
  setPricing(isAnnual: boolean) {
    this.isAnnual = isAnnual;
  }
  constructor(private session: SessionStorageService) {
    this.session.storage$
    .pipe(
      map(storage => storage[LocalStorageKey.SELECTED_PLAN])
    )
    .subscribe(plan => {
      this.selectedPlanName = plan;
    });
  }
}
