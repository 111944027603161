import { Component, Input } from '@angular/core';
import { ErrorMessage } from './errorMessage';

@Component({
  selector: 'input-error-list',
  templateUrl: './input-error-list.component.html',
  styleUrls: ['./input-error-list.component.css']
})
export class InputErrorListComponent {
  @Input() errors: ErrorMessage[] = [];
}
