import { Component } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { LocalStorageKey } from '../models/storageKey';
import { ErrorMessage } from '../common/input-error-list/errorMessage';

@Component({
  selector: 'app-collect-venue-data',
  templateUrl: './collect-venue-data.component.html',
  styleUrls: ['./collect-venue-data.component.css']
})
export class CollectVenueDataComponent {
  emailErrors: ErrorMessage[] = [];
  email: string = '';
  constructor(private sessionStorage: SessionStorageService) { }
  continue() {
    this.sessionStorage.setItem(LocalStorageKey.QUESTIONNAIRE, 'complete');
  }
}
