<div class="flex justify-center items-start min-h-screen bg-white px-4 sm:px-0">
    <div class="max-w-2xl w-full">
      <h1 class="heading-primary">
        What type of {{ selectedVenueType }} are you?
      </h1>
      <p class="text-gray-600 mb-6">
        Please select the most relevant option for your {{ selectedVenueType }}.
      </p>
      <div class="space-y-4 mb-6">
        <button
          *ngFor="let option of venueOptions"
          (click)="onOptionSelect(option)"
          class="w-full text-left px-4 py-3 border border-gray-300 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 outline-none"
          [ngClass]="{'border-blue-500': selectedSubType === option}"
        >
          {{ option }}
        </button>
        <div>
          <button
            (click)="onOptionSelect('Other')"
            class="w-full text-left px-4 py-3 border border-gray-300 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 outline-none"
            [ngClass]="{'border-blue-500': selectedSubType === 'Other'}"
          >
            Other
          </button>
          <div *ngIf="selectedSubType === 'Other'" class="mt-4">
            <label class="block text-gray-700 mb-2">Please specify:</label>
            <input
              type="text"
              [(ngModel)]="customOption"
              placeholder="Enter your option"
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>
      <button
        (click)="onContinue()"
        [disabled]="!isOptionValid()"
        class="w-full bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        Continue
      </button>
    </div>
  </div>
  