import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlanDetails } from './models/planDetails';
import { LocalStorageKey } from './models/storageKey';

export class ProspectData {
  emailAddress: string;
  orderVolume: string;
  internetAvailability: boolean;
  venueName: string;
  ownerPhoneNumber: string;
  password: string;

  constructor(
    emailAddress: string,
    orderVolume: string,
    internetAvailability: boolean,
    venueName: string,
    ownerPhoneNumber: string,
    password: string
  ) {
    this.emailAddress = emailAddress;
    this.orderVolume = orderVolume;
    this.internetAvailability = internetAvailability;
    this.venueName = venueName;
    this.ownerPhoneNumber = ownerPhoneNumber;
    this.password = password;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private storageSubject = new BehaviorSubject<Record<string, any>>({});
  storage$: Observable<Record<string, any>> = this.storageSubject.asObservable();

  plan: BehaviorSubject<PlanDetails | null> = new BehaviorSubject<PlanDetails | null>(null);
  newUserEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  newUserPassword: BehaviorSubject<string> = new BehaviorSubject<string>('');
  orderVolume: BehaviorSubject<string> = new BehaviorSubject<string>('');
  internetAvailability: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  venueName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  ownerPhoneNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
  acceptedLicenseAgreement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  password: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userLocale: BehaviorSubject<string> = new BehaviorSubject<string>('en-US');
  agreeTerms: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  businessName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  venueType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  confirmPassword: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
    const initialStorage = this.getAllStorage();
    this.storageSubject.next(initialStorage);

    window.addEventListener('storage', () => {
      this.storageSubject.next(this.getAllStorage());
    });

    this.userEmail.next(localStorage.getItem(LocalStorageKey.USER_EMAIL) || '');
    this.userLocale.next(localStorage.getItem(LocalStorageKey.USER_LOCALE) || 'en-US');
    const storedAgreeTerms = localStorage.getItem(LocalStorageKey.AGREE_TERMS);
    this.agreeTerms.next(storedAgreeTerms === 'true' || storedAgreeTerms === '1');
    this.businessName.next(localStorage.getItem(LocalStorageKey.BUSINESS_NAME) || '');
    this.password.next(localStorage.getItem(LocalStorageKey.USER_PASSWORD) || '');
    const rawConfirmPassword = localStorage.getItem(LocalStorageKey.USER_CONFIRM_PASSWORD) || '';
    this.confirmPassword.next(rawConfirmPassword);
  }

  setItem(key: string, value: any): void {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
    this.storageSubject.next(this.getAllStorage());
  }

  clearAllStorage(): void {
    localStorage.clear();
    this.storageSubject.next(this.getAllStorage());
  }

  getAllStorage(): Record<string, any> {
    const entries: Record<string, any> = {};

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const rawValue = localStorage.getItem(key);
        try {
          entries[key] = JSON.parse(rawValue as string);
        } catch (error) {
          console.warn(`Error parsing key "${key}". Using raw value.`, error);
          entries[key] = rawValue;
        }
      }
    }

    return entries;
  }

  setPlan(plan: PlanDetails) {
    this.plan.next(plan);
    this.setItem(LocalStorageKey.PLAN, plan);
  }

  setNewUserEmail(email: string) {
    this.newUserEmail.next(email);
  }

  setNewUserPassword(password: string) {
    this.newUserPassword.next(password);
  }

  setOrderVolume(volume: string) {
    this.orderVolume.next(volume);
  }

  setInternetAvailability(availability: boolean) {
    this.internetAvailability.next(availability);
  }

  setVenueName(name: string) {
    this.venueName.next(name);
  }

  setOwnerPhoneNumber(phoneNumber: string) {
    this.ownerPhoneNumber.next(phoneNumber);
  }

  setAcceptedLicenseAgreement(accepted: boolean) {
    this.acceptedLicenseAgreement.next(accepted);
  }

  setPassword(password: string) {
    this.password.next(password);
  }

  setUserEmail(email: string): void {
    this.userEmail.next(email);
    this.setItem(LocalStorageKey.USER_EMAIL, email);
  }

  setUserPassword(password: string): void {
    this.password.next(password);
    this.setItem(LocalStorageKey.USER_PASSWORD, password);
  }

  setConfirmPassword(confirmPassword: string): void {
    this.confirmPassword.next(confirmPassword);
    this.setItem(LocalStorageKey.USER_CONFIRM_PASSWORD, confirmPassword);
  }

  setBusinessName(businessName: string): void {
    this.businessName.next(businessName);
    this.setItem(LocalStorageKey.BUSINESS_NAME, businessName);
  }

  setUserLocale(locale: string): void {
    this.userLocale.next(locale);
    this.setItem(LocalStorageKey.USER_LOCALE, locale);
  }

  setAgreeTerms(agreed: boolean): void {
    this.agreeTerms.next(agreed);
    this.setItem(LocalStorageKey.AGREE_TERMS, agreed);
  }

  setVenueType(venue: string): void {
    this.venueType.next(venue);
    this.setItem(LocalStorageKey.VENUE_TYPE, venue);
  }

  newProspectData(): ProspectData {
    return new ProspectData(
      this.newUserEmail.value,
      this.orderVolume.value,
      this.internetAvailability.value,
      this.venueName.value,
      this.ownerPhoneNumber.value,
      this.password.value
    );
  }

  getUserEmail(): string {
    return this.userEmail.value;
  }

  getUserLocale(): string {
    return this.userLocale.value;
  }

  getAgreeTerms(): boolean {
    return this.agreeTerms.value;
  }

  getBusinessName(): string {
    return this.businessName.value;
  }

  getUserPassword(): string {
    return this.password.value;
  }

  getConfirmPassword(): string {
    return this.confirmPassword.value;
  }
}
