import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { LocalStorageKey } from '../models/storageKey';
import { Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'app-venue-type-continued',
  templateUrl: './venue-type-continued.component.html',
  styleUrls: ['./venue-type-continued.component.css']
})
export class VenueTypeContinuedComponent {
  selectedVenueType: string | null = null;
  selectedSubType: string = '';
  customOption: string = '';
  venueOptions: string[] = [];

  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {
    this.sessionStorageService.storage$
      .pipe(map(storage => storage[LocalStorageKey.VENUE_TYPE] || null))
      .subscribe(venueType => {
        this.selectedVenueType = venueType;

        if (!this.selectedVenueType) {
          this.router.navigate(['/venueType']);
        } else {
          this.loadOptions();
        }
      });
  }

  loadOptions(): void {
    const optionsMap: Record<string, string[]> = {
      'Concession Stand': [
        'Elementary/Middle School',
        'High School',
        'College',
        'Minor League',
        'Professional',
      ],
      'Food Truck': [
        'Taco',
        'BBQ',
        'Burger',
        'Pizza',
        'Seafood',
        'Dessert',
        'Beverage',
      ],
      'Golf Course': [
        'Clubhouse Dining',
        'Snack Bar/Refreshment Stand',
        'Beverage Cart',
        'Event Catering',
        'Bar & Lounge',
      ],
      'Stadium/Arena': [
        'High School',
        'College',
        'Minor League',
        'Professional',
      ],
      'Sports Complex': [
        'Multi-Sport Complex',
        'Soccer Complex',
        'Baseball/Softball Complex',
        'Basketball Facility',
        'Football Complex',
        'Hockey Arena',
        'Tennis and Pickleball Center',
        'Track and Field Complex',
        'Aquatics Center',
      ],
      'Cafeteria': [
        'School',
        'College Dining Hall',
        'Corporate',
        'Hospital',
        'Factory/Industrial',
        'Mall/Food Court',
      ],
      'Movie Theater': [
        'Concession Stand',
        'Pickup',
        'In-Seat Delivery',
        'Dine-In',
        'Self-Serve',
        'Alcohol & Bar',
      ],
      'Other': [
        'Bar & Grill',
        'Bowling Alley',
        'Brewery',
        'Cafe or Bistro',
        'Fast Food',
        'Fine Dining',
        'Movie Theatre',
        'Restaurant',
        'Sports Bar',
        'Sports Complex',
        'Yacht Club',
      ]
    };
    this.venueOptions = optionsMap[this.selectedVenueType!] || [];
  }

  onOptionSelect(option: string): void {
    this.selectedSubType = option;
    if (option !== 'Other') {
      this.customOption = '';
    }
  }

  isOptionValid(): boolean {
    return !!this.selectedSubType && (this.selectedSubType !== 'Other' || this.customOption.trim() !== '');
  }  

  onContinue(): void {
    if (!this.selectedSubType) {
      alert('Please select an option before continuing.');
      return;
    }

    let finalOption = this.selectedSubType;
    if (this.selectedSubType === 'Other' && this.customOption.trim() !== '') {
      finalOption = this.customOption;
    } else if (this.selectedSubType === 'Other') {
      alert('Please specify your option when selecting "Other".');
      return;
    }

    this.sessionStorageService.setItem(LocalStorageKey.VENUE_TYPE_CONTINUED, finalOption);
    this.sessionStorageService.setItem(LocalStorageKey.VENUE_CONTINUED, 'complete');

    this.router.navigate(['/choosePlan']);
  }
}
