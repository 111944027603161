import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage.service';
import { map } from 'rxjs';
import { LocalStorageKey } from '../models/storageKey';

@Component({
  selector: 'app-business-name',
  templateUrl: './business-name.component.html',
  styleUrls: ['./business-name.component.css']
})
export class BusinessNameComponent {
  businessName: string = '';

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {
    this.sessionStorageService.storage$
      .pipe(map(storage => storage[LocalStorageKey.BUSINESS_NAME] || ''))
      .subscribe(businessName => {
        this.businessName = businessName;
      });
  }

  onContinue() {
    if (!this.businessName) {
      alert('Please enter your business name.');
      return;
    }
  
    this.sessionStorageService.setItem(LocalStorageKey.BUSINESS_NAME, this.businessName);
    this.sessionStorageService.setItem(LocalStorageKey.BUSINESS, 'complete');
  
    this.router.navigate(['/venueType']);
  }
}
