<div class="flex space-x-4 justify-center">
    <button
      [routerLink]="[redirectLinkName]"
      class="w-12 h-12 rounded-full border-2 flex items-center justify-center"
      [ngClass]="{
        'bg-blue-500 border-blue-500 text-white': navState === 'complete',
        'bg-white border-blue-500 text-blue-500': navState === 'active',
        'bg-gray-100 border-gray-300 text-gray-400': navState === 'inactive'
      }"
      [disabled]="navState === 'inactive'"
      role="alert"
    >
      <svg
        *ngIf="navState === 'complete'"
        class="w-6 h-6 text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 12"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5.917 5.724 10.5 15 1.5"
        />
      </svg>
      <span *ngIf="navState === 'active'" class="font-medium text-blue-600">{{ index }}</span>
    </button>
  </div>
  