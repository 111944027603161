import { Component, Input } from '@angular/core';
import { ErrorMessage } from '../input-error-list/errorMessage';

@Component({
  selector: 'input-error-icon',
  templateUrl: './input-error-icon.component.html',
  styleUrls: ['./input-error-icon.component.css']
})
export class InputErrorIconComponent {
  @Input() errors: ErrorMessage[] = [];

}
