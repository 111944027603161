import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage.service';
import { AdminFunctionsService } from '../admin-functions.service';
import { map, tap } from 'rxjs';
import { LocalStorageKey } from '../models/storageKey';
import { CanComponentDeactivate } from '../guards/can-deactivate.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements CanComponentDeactivate {
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  locale: string = 'en-US';
  agreeTerms: boolean = false;
  isVerifying: boolean = false;
  businessName: string = '';
  passwordsDoNotMatch: boolean = false;
  formModified: boolean = false;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private adminFunctionsService: AdminFunctionsService
  ) {
    this.email = this.sessionStorageService.getUserEmail();
    this.locale = this.sessionStorageService.getUserLocale();
    this.agreeTerms = this.sessionStorageService.getAgreeTerms();
    this.businessName = this.sessionStorageService.getBusinessName();
    this.password = this.sessionStorageService.getUserPassword();
    this.confirmPassword = this.sessionStorageService.getConfirmPassword();
  }

  onFieldChange() {
    this.formModified = true;
  }

  canDeactivate(): boolean {
    if (this.formModified) {
      return confirm(
        'Are you sure you want to navigate to the review page? Progress will not be saved unless the continue button is clicked.'
      );
    }
    return true;
  }

  submitForm() {
    this.passwordsDoNotMatch = this.password !== this.confirmPassword;
    this.isVerifying = true;
    this.adminFunctionsService.checkForUser(this.email)
      .pipe(
        tap(result => console.log(`Raw result from service: ${result.isRegistered}`)),
        map(result => Boolean(result.isRegistered))
      )
      .subscribe(
        (isRegistered: boolean) => {
          this.isVerifying = false;
  
          if (isRegistered) {
            alert('Email already exists in our system.');
          } else {
            this.sessionStorageService.setUserEmail(this.email);
            this.sessionStorageService.setUserLocale(this.locale);
            this.sessionStorageService.setAgreeTerms(this.agreeTerms);
            this.sessionStorageService.setUserPassword(this.password);
            this.sessionStorageService.setConfirmPassword(this.confirmPassword);
            this.sessionStorageService.setBusinessName(this.businessName);
            this.sessionStorageService.setItem(LocalStorageKey.LOGIN, 'complete');
  
            this.formModified = false;
            this.router.navigate(['/venueType']);
          }
        },
        error => {
          this.isVerifying = false;
          console.error('Error verifying user:', error);
          alert('An error occurred while verifying the email. Please try again.');
        }
      );
  }
}