<div 
  class="card grid grid-rows-[28px_40px_50px_60px_1fr_30px_auto] gap-4 h-full rounded-2xl border-2 p-6"
  [ngClass]="{
    'border-indigo-600 shadow-lg': selectingPlanName === plan?.name,
    'border-gray-300': selectingPlanName !== plan?.name
  }"
>
  <!-- Row 1: Title -->
  <div class="flex items-center h-[28px]">
    <p class="font-semibold text-lg text-gray-900">{{ plan?.displayName }}</p>
  </div>

  <!-- Row 2: Tagline -->
  <div class="flex items-center h-[40px]">
    <p class="text-sm text-gray-600">{{ plan?.tagline }}</p>
  </div>

  <!-- Row 3: Price -->
  <div class="flex items-baseline h-[50px] space-x-1">
    <p class="text-4xl font-bold text-gray-900">${{ isAnnual ? plan?.annualMonthlyPrice : plan?.monthlyPrice }}</p>
    <span class="text-sm text-gray-500">/month</span>
  </div>

  <!-- Row 4: Billing Info & Description Lines -->
  <div class="flex flex-col justify-center h-[60px] space-y-1">
    <p class="text-sm text-green-600">
      {{ plan?.name === 'starter' ? 'No monthly fees' : 
        (isAnnual ? 'Billed annually' : 'Billed monthly') }}
      <span *ngIf="isAnnual"> ({{ '$' + plan?.annualPrice + '/year' }})</span>
    </p>
    <p class="text-xs text-gray-500" *ngIf="plan?.descriptionLine2">
      {{ plan?.descriptionLine2 }}
    </p>
    <p class="text-xs text-gray-500" *ngIf="plan?.descriptionLine3">
      {{ plan?.descriptionLine3 }}
    </p>
  </div>

  <!-- Row 5: Features -->
  <div class="flex-grow">
    <ul class="mt-2 text-sm text-gray-700 space-y-2">
      <li *ngFor="let feature of plan?.features" class="flex items-start">
        <span class="mr-2 text-green-500">✔</span>{{ feature }}
      </li>
    </ul>
  </div>

  <!-- Row 6: Cancellation Fee -->
  <div class="flex items-center h-[30px] text-center" *ngIf="plan?.name !== 'starter' && !isAnnual">
    <p class="text-xs text-gray-500">
      $300 cancellation fee if canceled within 1 year.
    </p>
  </div>

  <!-- Row 7: Button -->
  <div class="flex items-center pt-4">
    <button 
      class="w-full rounded-lg font-semibold py-2 transition-all duration-300"
      [ngClass]="{
        'bg-indigo-600 text-white hover:bg-indigo-500': selectingPlanName !== plan?.name,
        'border-2 border-indigo-600 text-indigo-600 bg-white': selectingPlanName === plan?.name
      }"
      (click)="setPlan(plan?.name || '')"
    >
      {{ selectingPlanName === plan?.name ? 'Selected' : 'Select plan' }}
    </button>
  </div>
</div>
