export enum LocalStorageKey {
  PLAN = 'plan',
  SELECTED_PLAN = 'selectedPlan',
  BILLING_FREQUENCY = 'billingFrequency',
  USER_VERIFIED = 'USER_VERIFIED',
  USER_AGREEMENT = 'USER_AGREEMENT',
  SETUP_USER_CREDENTIALS = 'SETUP_USER_CREDENTIALS',
  REVIEW_VENUE = 'REVIEW_VENUE',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  NEW_USER_EMAIL = 'newUserEmail',
  NEW_USER_PASSWORD = 'newUserPassword',
  INTERNET_AVAILABILITY = 'internetAvailability',
  VENUE_NAME = 'venueName',
  OWNER_PHONE_NUMBER = 'ownerPhoneNumber',
  ACCEPTED_LICENSE_AGREEMENT = 'acceptedLicenseAgreement',
  LOGIN = 'login',
  USER_EMAIL = 'userEmail',
  USER_PASSWORD = 'userPassword',
  USER_CONFIRM_PASSWORD = 'userConfirmPassword',
  USER_LOCALE = 'userLocale',
  AGREE_TERMS = 'agreeTerms',
  BUSINESS = 'business',
  BUSINESS_NAME = 'businessName',
  VENUE = 'venue',
  VENUE_TYPE = 'venueType',
  VENUE_CONTINUED = 'venueContinued',
  VENUE_TYPE_CONTINUED = 'venueTypeContinued'
}