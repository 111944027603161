import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../session-storage.service';
import { NavigationButtonState } from '../models/navigationButtonState';
import { Router } from '@angular/router';
import { LocalStorageKey } from '../models/storageKey';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  loginComplete: NavigationButtonState = 'inactive';
  venueTypeComplete: NavigationButtonState = 'inactive';
  planComplete: NavigationButtonState = 'inactive';
  userVerifyComplete: NavigationButtonState = 'inactive';
  userAgreementComplete: NavigationButtonState = 'inactive';
  questionComplete: NavigationButtonState = 'inactive';
  setupUserCredentials: NavigationButtonState = 'inactive';
  reviewVenueComplete: NavigationButtonState = 'inactive';
  confirmationActive: NavigationButtonState = 'inactive';

  constructor(private sessionStorage: SessionStorageService, private router: Router) {}

  ngOnInit(): void {
    this.sessionStorage.storage$.subscribe((storage) => {
      this.loginComplete = storage[LocalStorageKey.LOGIN] || 'active';
      this.venueTypeComplete = storage[LocalStorageKey.VENUE] || 'inactive';
      this.planComplete = storage[LocalStorageKey.PLAN] || 'inactive';
      this.reviewVenueComplete = storage[LocalStorageKey.REVIEW_VENUE] || 'inactive';
      this.confirmationActive = storage[LocalStorageKey.REVIEW_VENUE] || 'inactive';

      if (this.loginComplete === 'complete') {
        if (this.venueTypeComplete === 'complete') {
          if (this.planComplete === 'complete') {
            if (this.reviewVenueComplete === 'complete') {
              this.confirmationActive = 'active';
              this.router.navigate(['confirmation']);
            } else {
              this.reviewVenueComplete = 'active';
              this.router.navigate(['review']);
            }
          } else {
            this.planComplete = 'active';
            this.router.navigate(['choosePlan']);
          }
        } else {
          this.venueTypeComplete = 'active';
          this.router.navigate(['venueType']);
        }
      } else {
        this.loginComplete = 'active';
        this.router.navigate(['login']);
      }
    });
  }
}